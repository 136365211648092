// jshint ignore:start
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import PaginationComponent from '../../../components/utilities/Pagination';
import AdminLayout from '../../../layouts/AdminLayout';
import Api from '../../../services/Api';

const IndexPackage = () => {
  // title page
  document.title = 'Packages - LabM2M';

  // get token
  const accessToken = localStorage.getItem('accessToken');

  // state management
  const [packages, setPackages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');

  // fetch data
  const fetchData = async (pageNumber, searchData) => {
    // define variable page
    const page = pageNumber ? pageNumber : currentPage;

    // define variable searchQuery
    const searchQuery = searchData ? searchData : search;

    await Api.get(`/packages?keyword=${searchQuery}&page=${page}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      setPackages(response.data.data);
      setCurrentPage(response.data.meta.page);
      setPerPage(response.data.meta.perPage);
      setTotal(response.data.meta.totalCount);
    });
  };

  // use effect state
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchHandler = (e) => {
    e.preventDefault();

    fetchData(1, search);
  };

  const deletePackage = (_id) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'want to delete this data?',
      buttons: [
        {
          label: 'YES',
          onClick: async () => {
            await Api.delete(`/packages/${_id}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }).then(() => {
              toast.success('Data deleted successfully!', {
                duration: 3000,
                position: 'top-right',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              });

              fetchData();
            });
          },
        },
        {
          label: 'NO',
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <React.Fragment>
      <AdminLayout>
        <div className="row mt-4 mb-5">
          <div className="col-12">
            <div className="card border-0 rounded shadow-sm border-top-success">
              <div className="card-header">
                <span className="font-weight-bold">
                  <i className="fa fa-folder"></i> PACKAGES
                </span>
              </div>
              <div className="card-body">
                <form onSubmit={searchHandler} className="form-group">
                  <div className="input-group mb-3">
                    <Link
                      to="/superadmin/packages/create"
                      className="btn btn-md btn-success"
                    >
                      <i className="fa fa-plus-circle"></i> ADD NEW
                    </Link>
                    <input
                      type="text"
                      className="form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="search by package keyword"
                    />
                    <button type="submit" className="btn btn-md btn-success">
                      <i className="fa fa-search"></i> SEARCH
                    </button>
                  </div>
                </form>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped table-hovered">
                    <thead>
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Code</th>
                        <th scope="col">Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Max Channel</th>
                        <th scope="col">Max Data Log</th>
                        <th scope="col">Max Field</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {packages.map((data, index) => (
                        <tr key={index}>
                          {/**
                            <td className="text-center">
                            {++index + (currentPage - 1) * perPage}
                            </td>
                          */}
                          <td className="text-center">{++index}</td>
                          <td className="text-center">{data.code}</td>
                          <td className="text-center">{data.name}</td>
                          <td className="text-center">{data.description}</td>
                          <td className="text-center">{data.maxChannel}</td>
                          <td className="text-center">{data.maxDataLog}</td>
                          <td className="text-center">{data.maxField}</td>
                          <td className="text-center">
                            <Link
                              to={`/superadmin/packages/edit/${data._id}`}
                              className="btn btn-sm btn-primary me-2"
                            >
                              <i className="fa fa-pencil-alt"></i>
                            </Link>
                            <button
                              onClick={() => deletePackage(data._id)}
                              className="btn btn-sm btn-danger"
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <PaginationComponent
                    currentPage={currentPage}
                    perPage={perPage}
                    total={total}
                    onChange={(pageNumber) => fetchData(pageNumber)}
                    position="end"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </React.Fragment>
  );
};

export default IndexPackage;
