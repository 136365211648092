// jshint ignore:start
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Api from '../services/Api';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState('');
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('accessToken') != null) {
      const accessToken = localStorage.accessToken;
      const decode = jwtDecode(accessToken);
      const decode_role = decode['role'];

      // redirect dashboard page
      if (localStorage.getItem('accessToken') && decode_role === 'superadmin') {
        navigate('/superadmin/dashboard');
      } else if (localStorage.accessToken && decode_role === 'client') {
        navigate('/client/dashboard');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    await Api.post('/users/register', {
      name: name,
      email: email,
      password: password,
      company: company,
    })
      .then(() => {
        setLoading(false);

        toast.success('Register Successfully.', {
          duration: 3000,
          position: 'top-right',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });

        navigate('/login');
      })
      .catch((error) => {
        const status = error.response.status;

        const message =
          status === 400
            ? error.response.data.message[0]
            : error.response.data.message.split(':')[1];

        toast.error(message, {
          duration: 3000,
          position: 'top-right',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 mt-50">
            <div className="text-center mb-4">
              <h4>
                <i className="fa-solid fa-gears"></i> Lab Machine to Machine
              </h4>
            </div>
            <div className="card border-0 rounded shadow-sm">
              <div className="card-body">
                <div className="text-center">
                  <h6 className="fw-bold">REGISTER</h6>
                  <hr />
                </div>
                <form onSubmit={registerHandler}>
                  <label className="mb-1">
                    USERNAME <label style={{ color: 'red' }}>*</label>
                  </label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Username"
                    />
                  </div>
                  <label className="mb-1">
                    EMAIL ADDRESS <label style={{ color: 'red' }}>*</label>
                  </label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fa fa-envelope"></i>
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Address"
                    />
                  </div>
                  <label className="mb-1">
                    PASSWORD <label style={{ color: 'red' }}>*</label>
                  </label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fa fa-lock"></i>
                    </span>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                  </div>
                  <label className="mb-1">COMPANY</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fa fa-building"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      placeholder="Company Name"
                    />
                  </div>
                  <button
                    className="btn btn-success shadow-sm rounded-sm px-4 w-100"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? 'LOADING...' : 'REGISTER'}{' '}
                  </button>
                </form>
                <div className="mt-3">
                  Already have an account?
                  <Link to="/login" className="text-decoration-none">
                    {' '}
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
