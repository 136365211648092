// jshint ignore:start
import React from 'react';
import WebHeader from '../components/web/WebHeader';

const WebLayout = ({ children }) => {
  return (
    <React.Fragment>
      <div
        style={{ backgroundColor: 'white' }}
        className="container-fluid min-vh-100 d-flex flex-column"
      >
        <WebHeader />
        {children}
      </div>
    </React.Fragment>
  );
};

export default WebLayout;
