// jshint ignore:start
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminDashboard from '../pages/admin/dashboard/AdminDashboard';
import CreatePackage from '../pages/admin/packages/CreatePackage';
import EditPackage from '../pages/admin/packages/EditPackage';
import IndexPackage from '../pages/admin/packages/IndexPackage';
import CreateChannel from '../pages/client/channel/CreateChannel';
import EditChannel from '../pages/client/channel/EditChannel';
import ClientDashboard from '../pages/client/dashboard/ClientDashboard';
// import Dashboard from '../pages/Dashboard';
import CreateUser from '../pages/admin/users/CreateUser';
import EditUser from '../pages/admin/users/EditUser';
import IndexUser from '../pages/admin/users/IndexUser';
import IndexChannel from '../pages/client/channel/IndexChannel';
import MonitorChannel from '../pages/client/channel/MonitorChannel';
import SettingChannel from '../pages/client/channel/SettingChannel';
import Login from '../pages/Login';
import PublicMonitor from '../pages/PublicMonitor';
import Register from '../pages/Register';
import NodemcuMonitor from '../pages/web/NodemcuMonitor';
import Welcome from '../pages/Welcome';
import AdminRoutes from './AdminRoutes';
import ClientRoutes from './ClientRoutes';

const PageRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Login */}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Welcome />} />
        <Route path="/monitor/:_code" element={<PublicMonitor />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/documentation/nodemcu-monitor"
          element={<NodemcuMonitor />}
        />
        {/* 
        <Route path="/dashboard" element={<Dashboard />} />
      */}

        {/* Role as Admin */}
        <Route element={<AdminRoutes />}>
          <Route path="/superadmin/dashboard" element={<AdminDashboard />} />
          <Route path="/superadmin/users" element={<IndexUser />} />
          <Route path="/superadmin/users/create" element={<CreateUser />} />
          <Route path="/superadmin/users/edit/:_id" element={<EditUser />} />
          <Route path="/superadmin/packages" element={<IndexPackage />} />
          <Route
            path="/superadmin/packages/create"
            element={<CreatePackage />}
          />
          <Route
            path="/superadmin/packages/edit/:_id"
            element={<EditPackage />}
          />
        </Route>

        {/* Role as Client */}
        <Route element={<ClientRoutes />}>
          <Route path="/client/dashboard" element={<ClientDashboard />} />
          <Route path="/client/channels" element={<IndexChannel />} />
          <Route path="/client/channels/create" element={<CreateChannel />} />
          <Route path="/client/channels/edit/:_id" element={<EditChannel />} />
          <Route path="/client/channels/:_id" element={<MonitorChannel />} />
          <Route
            path="/client/channels/:_id/setting"
            element={<SettingChannel />}
          />
        </Route>

        {/* Web Route */}
      </Routes>
    </Router>
  );
};

export default PageRoutes;
