// jshint ignore:start
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import ChartSensor from '../../../components/utilities/ChartSensor';
import ClientLayout from '../../../layouts/ClientLayout';
import Api from '../../../services/Api';

const MonitorChannel = () => {
  // title
  document.title = 'Monitor Channel - LabM2M';

  // get token
  const accessToken = localStorage.getItem('accessToken');

  // state
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [writeKey, setWriteKey] = useState('');
  const [isPublic, setIsPublic] = useState('');
  const [readKey, setReadKey] = useState('');
  const [field1, setField1] = useState({});
  const [field1Name, setField1Name] = useState('');
  const [field2, setField2] = useState({});
  const [field2Name, setField2Name] = useState('');
  const [field3, setField3] = useState({});
  const [field3Name, setField3Name] = useState('');
  const [field4, setField4] = useState({});
  const [field4Name, setField4Name] = useState('');
  const [field5, setField5] = useState({});
  const [field5Name, setField5Name] = useState('');
  const [field6, setField6] = useState({});
  const [field6Name, setField6Name] = useState('');
  const [field7, setField7] = useState({});
  const [field7Name, setField7Name] = useState('');
  const [field8, setField8] = useState({});
  const [field8Name, setField8Name] = useState('');

  //buttons
  const [valueButton1, setValueButton1] = useState('');
  const [textButton1, setTextButton1] = useState('');
  const [valueButton2, setValueButton2] = useState('');
  const [textButton2, setTextButton2] = useState('');
  const [valueButton3, setValueButton3] = useState('');
  const [textButton3, setTextButton3] = useState('');
  const [valueButton4, setValueButton4] = useState('');
  const [textButton4, setTextButton4] = useState('');

  const { _id } = useParams();

  const getDataMonitor = async (code, readKey) => {
    await Api.get(`/channels/${code}/feeds?apikey=${readKey}&limit=20`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      const channel = response.data.channel;
      const feeds = response.data.feeds;

      setCode(code);
      setReadKey(readKey);
      setField1(channel.hasOwnProperty('f1') ? feeds : {});
      setField1Name(channel.hasOwnProperty('f1') ? channel.f1 : '');
      setField2(channel.hasOwnProperty('f2') ? feeds : {});
      setField2Name(channel.hasOwnProperty('f2') ? channel.f2 : '');
      setField3(channel.hasOwnProperty('f3') ? feeds : {});
      setField3Name(channel.hasOwnProperty('f3') ? channel.f3 : '');
      setField4(channel.hasOwnProperty('f4') ? feeds : {});
      setField4Name(channel.hasOwnProperty('f4') ? channel.f4 : '');
      setField5(channel.hasOwnProperty('f5') ? feeds : {});
      setField5Name(channel.hasOwnProperty('f5') ? channel.f5 : '');
      setField6(channel.hasOwnProperty('f6') ? feeds : {});
      setField6Name(channel.hasOwnProperty('f6') ? channel.f6 : '');
      setField7(channel.hasOwnProperty('f7') ? feeds : {});
      setField7Name(channel.hasOwnProperty('f7') ? channel.f7 : '');
      setField8(channel.hasOwnProperty('f8') ? feeds : {});
      setField8Name(channel.hasOwnProperty('f8') ? channel.f8 : '');
    });
  };

  const getChannelById = async () => {
    await Api.get(`/channels/${_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      const data = response.data.data;
      const controls = data.transformControls;

      setName(data.name);
      setCode(data.code);
      setReadKey(data.readKey);
      setIsPublic(data.isPublic);
      setWriteKey(data.writeKey);

      setValueButton1(
        typeof controls[0] !== 'undefined' ? controls[0].value : ''
      );
      setTextButton1(
        typeof controls[0] !== 'undefined' ? controls[0].text : ''
      );
      setValueButton2(
        typeof controls[1] !== 'undefined' ? controls[1].value : ''
      );
      setTextButton2(
        typeof controls[1] !== 'undefined' ? controls[1].text : ''
      );
      setValueButton3(
        typeof controls[2] !== 'undefined' ? controls[2].value : ''
      );
      setTextButton3(
        typeof controls[2] !== 'undefined' ? controls[2].text : ''
      );
      setValueButton4(
        typeof controls[3] !== 'undefined' ? controls[3].value : ''
      );
      setTextButton4(
        typeof controls[3] !== 'undefined' ? controls[3].text : ''
      );

      getDataMonitor(data.code, data.readKey);
    });
  };

  const publish = async (button) => {
    let value = null;
    switch (button) {
      case 'btn1':
        value = valueButton1;
        break;
      case 'btn2':
        value = valueButton2;
        break;
      case 'btn3':
        value = valueButton3;
        break;
      case 'btn4':
        value = valueButton4;
        break;
      default:
        break;
    }

    if (value != null) {
      await Api.get(
        `/controls/publish?topic=${code}/${writeKey}&value=${value}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      ).then((response) => {
        const data = response.data;

        toast.success(data.message, {
          duration: 3000,
          position: 'top-right',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
      });
    } else {
      toast.error('error', {
        duration: 3000,
        position: 'top-right',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    }
  };

  useEffect(() => {
    getChannelById();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <ClientLayout>
        <div className="row mt-4">
          <div className="col-12">
            <div className="card border-0 rounded shadow-sm border-top-success">
              <div className="card-header">
                <span className="font-weight-bold">
                  <Link to="/client/channels" className="text-decoration-none">
                    CHANNELS
                  </Link>{' '}
                  / MONITOR CHANNEL
                </span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h4>{name}</h4>
                    <p>
                      Channel Code : <strong>{code}</strong>
                      <br />
                      Access :{' '}
                      <strong>{isPublic ? 'public' : 'private'}</strong>
                    </p>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="row">
                      {textButton1 !== '' ? (
                        <div className="col-3">
                          <button
                            className="btn btn-primary btn-block w-100"
                            onClick={() => publish('btn1')}
                          >
                            {textButton1}
                          </button>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {textButton2 !== '' ? (
                        <div className="col-3">
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => publish('btn2')}
                          >
                            {textButton2}
                          </button>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {textButton3 !== '' ? (
                        <div className="col-3">
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => publish('btn3')}
                          >
                            {textButton3}
                          </button>
                        </div>
                      ) : (
                        <div className="col-md-3"></div>
                      )}

                      {textButton4 !== '' ? (
                        <div className="col-3">
                          <button
                            className="btn btn-primary w-100"
                            onClick={() => publish('btn4')}
                          >
                            {textButton4}
                          </button>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>

                  <hr />

                  {field1Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field1Name}
                        times={field1.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field1.map((v) => {
                          return v.f1;
                        })}
                        code={code}
                        readKey={readKey}
                        fieldActive="f1"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field2Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field2Name}
                        times={field2.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field2.map((v) => {
                          return v.f2;
                        })}
                        code={code}
                        readKey={readKey}
                        fieldActive="f2"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field3Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field3Name}
                        times={field3.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field3.map((v) => {
                          return v.f3;
                        })}
                        code={code}
                        readKey={readKey}
                        fieldActive="f3"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field4Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field4Name}
                        times={field4.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field4.map((v) => {
                          return v.f4;
                        })}
                        code={code}
                        readKey={readKey}
                        fieldActive="f4"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field5Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field5Name}
                        times={field3.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field5.map((v) => {
                          return v.f5;
                        })}
                        code={code}
                        readKey={readKey}
                        fieldActive="f5"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field6Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field6Name}
                        times={field6.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field6.map((v) => {
                          return v.f6;
                        })}
                        code={code}
                        readKey={readKey}
                        fieldActive="f6"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field7Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field7Name}
                        times={field7.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field7.map((v) => {
                          return v.f7;
                        })}
                        code={code}
                        readKey={readKey}
                        fieldActive="f7"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field8Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field8Name}
                        times={field8.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field8.map((v) => {
                          return v.f8;
                        })}
                        code={code}
                        readKey={readKey}
                        fieldActive="f8"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ClientLayout>
    </React.Fragment>
  );
};

export default MonitorChannel;
