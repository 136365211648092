// jshint ignore:start
import React, { useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import AdminLayout from '../../../layouts/AdminLayout';
import Api from '../../../services/Api';

const CreatePackage = () => {
  // title page
  document.title = 'Create Package - LabM2M';

  // get token
  const accessToken = localStorage.getItem('accessToken');

  // state
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [maxChannel, setMaxChannel] = useState(1);
  const [maxDataLog, setMaxDataLog] = useState(100);
  const [maxField, setMaxField] = useState(1);

  // navigate
  const navigate = useNavigate();

  const storePackage = async (e) => {
    e.preventDefault();

    // const formData = new FormData();

    // formData.append('code', code);
    // formData.append('name', name);
    // formData.append('description', description);
    // formData.append('maxChannel', maxChannel);
    // formData.append('maxDataLog', maxDataLog);
    // formData.append('maxField', maxField);

    await Api.post(
      '/packages',
      {
        code: code,
        name: name,
        description: description,
        maxChannel: parseInt(maxChannel),
        maxDataLog: parseInt(maxDataLog),
        maxField: parseInt(maxField),
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(() => {
        toast.success('Data saved successfully!', {
          duration: 3000,
          position: 'top-right',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });

        navigate('/superadmin/packages');
      })
      .catch((error) => {
        const status = error.response.status;

        const message =
          status === 400
            ? error.response.data.message[0]
            : error.response.data.message.split(':')[1];

        toast.error(message, {
          duration: 3000,
          position: 'top-right',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
      });
  };

  return (
    <React.Fragment>
      <AdminLayout>
        <div className="row mt-4 mb-5">
          <div className="col-12">
            <div className="card border-0 rounded shadow-sm border-top-success">
              <div className="card-header">
                <span className="font-weight-bold">
                  <Link
                    to="/superadmin/packages"
                    className="text-decoration-none"
                  >
                    PACKAGES
                  </Link>{' '}
                  / ADD NEW PACKAGE
                </span>
              </div>
              <div className="card-body">
                <form onSubmit={storePackage} encType="multipart/form">
                  <div className="mb-3">
                    <label className="form-label fw-bold">Package Code</label>
                    <input
                      type="text"
                      className="form-control"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      placeholder="Enter Package Code"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">Package Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Package Name"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">
                      Package Description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Enter Package Description"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">
                      Package Max Channel
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={maxChannel}
                      onChange={(e) => setMaxChannel(e.target.value)}
                      placeholder="Enter Package Max Channel"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">
                      Package Max Data Log
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={maxDataLog}
                      onChange={(e) => setMaxDataLog(e.target.value)}
                      placeholder="Enter Package Max Data Log"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">
                      Package Max Field
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={maxField}
                      onChange={(e) => setMaxField(e.target.value)}
                      placeholder="Enter Package Max Field"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-md btn-success me-2"
                    >
                      <i className="fa fa-save"></i> SAVE
                    </button>
                    <button type="reset" className="btn btn-md btn-warning">
                      <i className="fa fa-redo"></i> RESET
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </React.Fragment>
  );
};

export default CreatePackage;
