// jshint ignore:start
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import ClientLayout from '../../../layouts/ClientLayout';
import Api from '../../../services/Api';

const SettingChannel = () => {
  document.title = 'Setting Channel - LabM2M';

  // get token
  const accessToken = localStorage.getItem('accessToken');

  // state
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [writeKey, setWriteKey] = useState('');
  const [readKey, setReadKey] = useState('');
  const [isPublic, setIsPublic] = useState('');

  const { _id } = useParams();

  const getChannelById = async () => {
    await Api.get(`/channels/${_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      const data = response.data.data;

      setName(data.name);
      setCode(data.code);
      setWriteKey(data.writeKey);
      setReadKey(data.readKey);
      setIsPublic(data.isPublic);
    });
  };

  const generateNewToken = async (tokenType) => {
    confirmAlert({
      title: 'Confirm',
      message: `are you sure you want to generate new ${tokenType} API key?`,
      buttons: [
        {
          label: 'Ok',
          onClick: async () => {
            await Api.get(`/channels/${_id}/new-apikey/${tokenType}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }).then((response) => {
              const data = response.data.data;

              if (tokenType === 'write') {
                setWriteKey(data.writeKey);
              } else {
                setReadKey(data.readKey);
              }

              toast.success(`Generate new ${tokenType} token successfully!`, {
                duration: 3000,
                position: 'top-right',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              });
            });
          },
        },
        {
          label: 'Cancel',
          onClick: () => {},
        },
      ],
    });
  };

  // use effect
  useEffect(() => {
    getChannelById();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <ClientLayout>
        <div className="row mt-4 mb-5">
          <div className="col-12">
            <div className="card border-0 rounded shadow-sm border-top-success">
              <div className="card-header">
                <span className="font-weight-bold">
                  <Link to="/client/channels" className="text-decoration-none">
                    CHANNELS
                  </Link>{' '}
                  / SETTING CHANNEL
                </span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <h4>{name}</h4>
                    <p>
                      Channel Code : <strong>{code}</strong>
                      <br />
                      Access :{' '}
                      <strong>{isPublic ? 'public' : 'private'}</strong>
                    </p>
                    <hr />
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <h5>Write API Key</h5>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={writeKey}
                          disabled
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <button
                          className="btn btn-primary"
                          onClick={() => generateNewToken('write')}
                        >
                          Generate New Write Key
                        </button>
                      </div>

                      <h5>Read API Key</h5>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={readKey}
                          disabled
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <button
                          className="btn btn-primary"
                          onClick={() => generateNewToken('read')}
                        >
                          Generate New Read Key
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 row">
                    <h5>Help</h5>
                    <p>
                      The write key and read key will be generated automatically
                      when you create the channel. You can update both at any
                      time. Please note that both keys will be used to access
                      the data you have submitted to the LabM2M platform and you
                      will need to make adjustments when the keys are changed.
                    </p>
                    <h5>Keys Information</h5>
                    <p>
                      <li>
                        <strong>Write API Key</strong> : use this key to write
                        or send data to the channel.
                      </li>
                      <li>
                        <strong>Read API Key</strong> : use this key to read or
                        get data from channel.
                      </li>
                    </p>
                    <h5>How to use</h5>
                    <label>
                      <span style={{ color: 'green' }}>
                        <strong>Write data channel</strong>
                      </span>
                    </label>
                    <pre>
                      GET{' '}
                      <span style={{ color: 'red' }}>
                        {process.env.REACT_APP_BASEURL}/update?apikey=
                      </span>
                      <span style={{ color: 'green' }}>{writeKey}</span>
                      <span style={{ color: 'red' }}>&f1=</span>
                      <span style={{ color: 'green' }}>10</span>
                    </pre>
                    <label>
                      <span style={{ color: 'green' }}>
                        <strong>Read a channel datas</strong>
                      </span>
                    </label>
                    <pre>
                      GET{' '}
                      <span style={{ color: 'red' }}>
                        {process.env.REACT_APP_BASEURL}/channels/
                        <span style={{ color: 'green' }}>{code}</span>
                        /feeds?apikey=
                        <span style={{ color: 'green' }}>{readKey}</span>
                        &limit=
                        <span style={{ color: 'green' }}>10</span>
                      </span>
                    </pre>
                    <label>
                      <span style={{ color: 'green' }}>
                        <strong>Read a channel field</strong>
                      </span>
                    </label>
                    <pre>
                      GET{' '}
                      <span style={{ color: 'red' }}>
                        {process.env.REACT_APP_BASEURL}/channels/
                        <span style={{ color: 'green' }}>{code}</span>
                        /fields/<span style={{ color: 'green' }}>f1</span>
                        ?apikey=
                        <span style={{ color: 'green' }}>{readKey}</span>
                        &limit=
                        <span style={{ color: 'green' }}>10</span>
                      </span>
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ClientLayout>
    </React.Fragment>
  );
};

export default SettingChannel;
