// jshint ignore:start
import jwtDecode from 'jwt-decode';
import { Navigate, Outlet } from 'react-router-dom';

const ClientRoutes = () => {
  //token from cookie
  const accessToken = localStorage.accessToken;
  const decode = jwtDecode(accessToken);
  const decode_role = decode['role'];

  // eslint-disable-next-line eqeqeq
  const token = accessToken != null && decode_role == 'client';
  return token ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ClientRoutes;
