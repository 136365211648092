// jshint ignore:start
import React from 'react';
// import { Link } from 'react-router-dom';
import PackagesImg from '../../../assets/images/Packages.png';
import PackagesCreateImg from '../../../assets/images/Package_create.png';
import PackagesEditImg from '../../../assets/images/Package_edit.png';
import AdminLayout from '../../../layouts/AdminLayout';

const AdminDashboard = () => {
  // get accessToken
  // const accessToken = localStorage.getItem('accessToken');

  return (
    <React.Fragment>
      <AdminLayout>
        <div className="row mt-4 mb-5">
          <div className="col-12">
            <div className="card border-0 rounded shadow-sm border-top-success">
              <div className="card-header">
                <span className="font-weight-bold">DASHBOARD</span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h2>Admin Docs LabM2M!</h2>
                    <hr />
                  </div>
                  <div className="col-md-6">
                    <h5>1. Packages LabM2M</h5>
                    <p style={{ textAlign: 'justify' }}>
                      Channels adalah tempat pembuatan 1 project. Dalam channel
                      ini, kita dapat mengatur suatu channel sesuai dengan
                      kebutuhan. Sebelum kita membuat channels, akan ditampilkan
                      seperti berikut.
                    </p>
                    <img
                      className="d-flex align-items-center justify-content-center w-100 mt-2 mb-3"
                      src={PackagesImg}
                      alt="packages"
                    />
                    <hr />
                    <h5>2. Add Package</h5>
                    <p style={{ textAlign: 'justify' }}>
                      Add Channel digunakan untuk membuat channel baru. Untuk
                      sekarang channel ini maksimal 1 untuk paket free. Namun
                      dapat kita tambahkan lagi sesuai kebutuhan. Didalamnya
                      anda harus mengisikan nama channel, deskripsi channel,
                      field untuk kebutuhan sensor, button value dan teks untuk
                      kebutuhan kontrol aktuator.
                    </p>
                    <img
                      className="d-flex align-items-center justify-content-center w-100 mt-2 mb-3"
                      src={PackagesCreateImg}
                      alt="package-create"
                    />
                    <hr />
                    <h5>3. Edit Package</h5>
                    <p style={{ textAlign: 'justify' }}>
                      Bagian ini sama seperti pertama kali kita membuat channel.
                      Namun ditambahkan pilihan untuk mengatur status public
                      atau private channel anda.
                    </p>
                    <img
                      className="d-flex align-items-center justify-content-center w-100 mt-2 mb-3"
                      src={PackagesEditImg}
                      alt="package-edit"
                    />
                    <hr />
                  </div>
                  <div className="col-md-6">
                    <h3 className="text-center">ABOUT FOUNDER</h3>
                    <hr />
                    <a
                      href={'https://ahmada-portfolio.netlify.app'}
                      className="text-decoration-none"
                    >
                      <h5>PERSONAL PORTFOLIO</h5>
                    </a>
                    <a
                      href={'https://github.com/ahmadaait'}
                      className="text-decoration-none"
                    >
                      <h5>GITHUB</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </React.Fragment>
  );
};

export default AdminDashboard;
