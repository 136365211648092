// jshint ignore:start
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PaginationComponent from '../components/utilities/Pagination';
import WebLayout from '../layouts/WebLayout';
import Api from '../services/Api';

const Welcome = () => {
  const [channels, setChannels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');

  // fetch data
  const fetchData = async (pageNumber, searchData) => {
    // define variable page
    const page = pageNumber ? pageNumber : currentPage;

    // define variable searchQuery
    const searchQuery = searchData ? searchData : search;

    await Api.get(
      `/channels/public/list?keyword=${searchQuery}&perpage=8&page=${page}`
    ).then((response) => {
      setChannels(response.data.data);
      setCurrentPage(response.data.meta.page);
      setPerPage(response.data.meta.perpage);
      setTotal(response.data.meta.totalCount);
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchHandler = (e) => {
    e.preventDefault();

    fetchData(1, search);
  };

  return (
    <React.Fragment>
      <WebLayout>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="container">
                  <div className="row height d-flex justify-content-center align-items-center">
                    <form onSubmit={searchHandler}>
                      <div className="col-md-12 row">
                        <div className="search col-8">
                          <input
                            type="text"
                            className="form-control"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search by code or username . . ."
                          />
                        </div>

                        <div className="col-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {channels.map((data, index) => (
                <div className="col-md-3">
                  <div className="card mb-4" style={{ height: '200px' }}>
                    <div className="card-body">
                      <h5 className="card-title">{data.name}</h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        <span style={{ color: 'green' }}>
                          {data.author.name}
                        </span>
                      </h6>
                      <p className="card-text">{data.description}</p>
                    </div>
                    <div className="card-footer">
                      <Link
                        to={`/monitor/${data.code}`}
                        className="btn btn-sm btn-warning me-2 w-100"
                      >
                        <i className="fa fa-eye"></i> Show
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <PaginationComponent
              currentPage={currentPage}
              perPage={perPage}
              total={total}
              onChange={(pageNumber) => fetchData(pageNumber)}
              position="center"
            />
          </div>
        </div>
      </WebLayout>
    </React.Fragment>
  );
};

export default Welcome;
