// jshint ignore:start
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import ClientLayout from '../../../layouts/ClientLayout';
import Api from '../../../services/Api';

const CreateChannel = () => {
  // title page
  document.title = 'Create Channel - LabM2M';

  // get token
  const accessToken = localStorage.getItem('accessToken');

  // state
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [valueField, setValueField] = useState('');
  const [valueField2, setValueField2] = useState('');
  const [valueField3, setValueField3] = useState('');
  const [valueField4, setValueField4] = useState('');
  const [valueField5, setValueField5] = useState('');
  const [valueField6, setValueField6] = useState('');
  const [valueField7, setValueField7] = useState('');
  const [valueField8, setValueField8] = useState('');

  //buttons
  const [valueButton1, setValueButton1] = useState('');
  const [textButton1, setTextButton1] = useState('');
  const [valueButton2, setValueButton2] = useState('');
  const [textButton2, setTextButton2] = useState('');
  const [valueButton3, setValueButton3] = useState('');
  const [textButton3, setTextButton3] = useState('');
  const [valueButton4, setValueButton4] = useState('');
  const [textButton4, setTextButton4] = useState('');

  // const [isChecked2, setIsChecked2] = useState(false);

  // navigate
  const navigate = useNavigate();

  const reset = async () => {
    setName('');
    setDescription('');
    setLat('');
    setLong('');
    setValueField('');
    setValueField2('');
    setValueField3('');
    setValueField4('');
    setValueField5('');
    setValueField6('');
    setValueField7('');
    setValueField8('');

    setValueButton1('');
    setTextButton1('');
    setValueButton2('');
    setTextButton2('');
    setValueButton3('');
    setTextButton3('');
    setValueButton4('');
    setTextButton4('');
  };

  // store channel
  const storeChannel = async (e) => {
    e.preventDefault();

    // const formData = new FormData();
    // formData.append('name', valueField.name);
    // formData.append('description', valueField.description);
    // formData.append('fields', JSON.stringify(valueField.fields));

    await Api.post(
      '/channels',
      {
        // name: valueField.name,
        // description: valueField.description,
        // fields: valueField.fields,
        name: name,
        description: description,
        lat: lat,
        long: long,
        fields: [
          valueField,
          valueField2,
          valueField3,
          valueField4,
          valueField5,
          valueField6,
          valueField7,
          valueField8,
        ],
        controls: [
          { text: textButton1, value: valueButton1 },
          { text: textButton2, value: valueButton2 },
          { text: textButton3, value: valueButton3 },
          { text: textButton4, value: valueButton4 },
        ],
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(() => {
        toast.success('Data saved successfully!', {
          duration: 3000,
          position: 'top-right',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });

        navigate('/client/channels');
      })
      .catch((error) => {
        const status = error.response.status;

        const message =
          status === 400
            ? error.response.data.message[0]
            : error.response.data.message.split(':')[1];

        toast.error(message, {
          duration: 3000,
          position: 'top-right',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
      });
  };

  return (
    <React.Fragment>
      <ClientLayout>
        <div className="row mt-4 mb-5">
          <div className="col-12">
            <div className="card border-0 rounded shadow-sm border-top-success">
              <div className="card-header">
                <span className="font-weight-bold">
                  <Link to="/client/channels" className="text-decoration-none">
                    CHANNELS
                  </Link>{' '}
                  / ADD NEW CHANNEL
                </span>
              </div>
              <div className="card-body">
                <form onSubmit={storeChannel} encType="multipart/form">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="mb-3">
                        <label className="form-label fw-bold">
                          Channel Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Enter Channel Name"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label fw-bold">
                          Channel Description
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Enter Channel Description"
                        >
                          {description}
                        </textarea>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="text-center">
                        <label className="form-label fw-bold mb-4">
                          LATITUDE
                        </label>
                      </div>
                      <div className="form-group row">
                        <label className="form-label fw-bold col-md-2">
                          Lat
                        </label>
                        <div className="col-md-10">
                          <input
                            className="mb-3 form-control"
                            value={lat}
                            placeholder="Enter Latitude"
                            onChange={(e) => setLat(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="text-center">
                        <label className="form-label fw-bold mb-4">
                          LONGITUDE
                        </label>
                      </div>
                      <div className="form-group row">
                        <label className="form-label fw-bold col-md-2">
                          Long
                        </label>
                        <div className="col-md-10">
                          <input
                            className="mb-3 form-control"
                            value={long}
                            placeholder="Enter Longitude"
                            onChange={(e) => setLong(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-center">
                        <label className="form-label fw-bold mb-4">
                          SENSOR MONITORING
                        </label>
                      </div>
                      <div className="form-group row">
                        <label className="form-label fw-bold col-md-2">
                          Field 1
                        </label>
                        <div className="col-md-10">
                          <input
                            className="mb-3 form-control"
                            value={valueField}
                            placeholder="Enter Field Name"
                            onChange={(e) => setValueField(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="form-label fw-bold col-md-2">
                          Field 2
                        </label>
                        <div className="col-md-10">
                          <input
                            className="mb-3 form-control"
                            value={valueField2}
                            placeholder="Enter Field Name"
                            onChange={(e) => setValueField2(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label className="form-label fw-bold col-md-2">
                          Field 3
                        </label>
                        <div className="col-md-10">
                          <input
                            className="mb-3 form-control"
                            value={valueField3}
                            placeholder="Enter Field Name"
                            onChange={(e) => setValueField3(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label className="form-label fw-bold col-md-2">
                          Field 4
                        </label>
                        <div className="col-md-10">
                          <input
                            className="mb-3 form-control"
                            value={valueField4}
                            placeholder="Enter Field Name"
                            onChange={(e) => setValueField4(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label className="form-label fw-bold col-md-2">
                          Field 5
                        </label>
                        <div className="col-md-10">
                          <input
                            className="mb-3 form-control"
                            value={valueField5}
                            placeholder="Enter Field Name"
                            onChange={(e) => setValueField5(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label className="form-label fw-bold col-md-2">
                          Field 6
                        </label>
                        <div className="col-md-10">
                          <input
                            className="mb-3 form-control"
                            value={valueField6}
                            placeholder="Enter Field Name"
                            onChange={(e) => setValueField6(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label className="form-label fw-bold col-md-2">
                          Field 7
                        </label>
                        <div className="col-md-10">
                          <input
                            className="mb-3 form-control"
                            value={valueField7}
                            placeholder="Enter Field Name"
                            onChange={(e) => setValueField7(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <label className="form-label fw-bold col-md-2">
                          Field 8
                        </label>
                        <div className="col-md-10 mb-4">
                          <input
                            className="mb-3 form-control"
                            value={valueField8}
                            placeholder="Enter Field Name"
                            onChange={(e) => setValueField8(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="text-center">
                        <label className="form-label fw-bold mb-4">
                          ACTUATOR CONTROLLING (MQTT)
                        </label>
                      </div>
                      <div className="form-group row">
                        <label className="form-label fw-bold col-md-2">
                          Button 1
                        </label>
                        <div className="col-md-5">
                          <input
                            className="mb-3 form-control"
                            value={valueButton1}
                            placeholder="Value"
                            onChange={(e) => setValueButton1(e.target.value)}
                          />
                        </div>
                        <div className="col-md-5">
                          <input
                            className="mb-3 form-control"
                            value={textButton1}
                            placeholder="Text"
                            onChange={(e) => setTextButton1(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="form-label fw-bold col-md-2">
                          Button 2
                        </label>
                        <div className="col-md-5">
                          <input
                            className="mb-3 form-control"
                            value={valueButton2}
                            placeholder="Value"
                            onChange={(e) => setValueButton2(e.target.value)}
                          />
                        </div>
                        <div className="col-md-5">
                          <input
                            className="mb-3 form-control"
                            value={textButton2}
                            placeholder="Text"
                            onChange={(e) => setTextButton2(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="form-label fw-bold col-md-2">
                          Button 3
                        </label>
                        <div className="col-md-5">
                          <input
                            className="mb-3 form-control"
                            value={valueButton3}
                            placeholder="Value"
                            onChange={(e) => setValueButton3(e.target.value)}
                          />
                        </div>
                        <div className="col-md-5">
                          <input
                            className="mb-3 form-control"
                            value={textButton3}
                            placeholder="Text"
                            onChange={(e) => setTextButton3(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="form-label fw-bold col-md-2">
                          Button 4
                        </label>
                        <div className="col-md-5">
                          <input
                            className="mb-3 form-control"
                            value={valueButton4}
                            placeholder="Value"
                            onChange={(e) => setValueButton4(e.target.value)}
                          />
                        </div>
                        <div className="col-md-5">
                          <input
                            className="mb-3 form-control"
                            value={textButton4}
                            placeholder="Text"
                            onChange={(e) => setTextButton4(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 text-end">
                      <button
                        type="submit"
                        className="btn btn-md btn-success me-2"
                      >
                        <i className="fa fa-save"></i> SAVE
                      </button>
                      <button
                        type="reset"
                        className="btn btn-md btn-warning"
                        onClick={reset}
                      >
                        <i className="fa fa-redo"></i> RESET
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ClientLayout>
    </React.Fragment>
  );
};

export default CreateChannel;
