// jshint ignore:start
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../services/Api';

const WebHeader = () => {
  const [user, setUser] = useState({});

  const accessToken = localStorage.getItem('accessToken');

  const fetchData = async () => {
    // fetch on rest api
    await Api.get('/users/profile', {
      headers: {
        // header bearer + token
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      setUser(response.data.data);
    });
  };

  const controlInterval = () => {
    // Get a reference to the last interval + 1
    const interval_id = window.setInterval(function () {},
    Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  };

  useEffect(() => {
    fetchData();
    controlInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        <Link
          to={'/'}
          className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
        >
          Lab Machine to Machine Gateway
        </Link>

        <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
          <a href="https://labm2m.com" className="nav-link px-2 link-dark">
            Home
          </a>

          <Link className="nav-link px-2 link-dark">FAQs</Link>

          <Link to={'/documentation'} className="nav-link px-2 link-dark">
            Documentation
          </Link>
        </ul>

        {accessToken != null ? (
          <div className="col-md-3 text-end">
            <Link
              type="button"
              to="/client/dashboard"
              className="btn btn-outline-primary me-2"
            >
              {user.name}
            </Link>
          </div>
        ) : (
          <div className="col-md-3 text-end">
            <Link
              type="button"
              to={'/login'}
              className="btn btn-outline-primary me-2"
            >
              Login
            </Link>

            <Link type="button" to={'/register'} className="btn btn-primary">
              Sign-up
            </Link>
          </div>
        )}
      </header>
    </React.Fragment>
  );
};

export default WebHeader;
