// jshint ignore:start
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const AdminSidebar = () => {
  // assigning location variable
  const location = useLocation();

  // destructuring pathname from location
  const { pathname } = location;

  // js split method to get the name of path in array
  const splitLocation = pathname.split('/');

  return (
    <React.Fragment>
      <div className="list-group list-group-flush">
        {/** Dashboard */}
        <Link
          className={
            splitLocation[2] === 'dashboard'
              ? 'list-group-item list-group-item-action list-group-item-light p-3 text-uppercase active'
              : 'list-group-item list-group-item-action list-group-item-light p-3 text-uppercase'
          }
          to="/superadmin/dashboard"
        >
          <i className="fa fa-tachometer-alt me-2" />
          Dashboard
        </Link>

        {/** Packages */}
        <Link
          className={
            splitLocation[2] === 'pakcages'
              ? 'list-group-item list-group-item-action list-group-item-light p-3 text-uppercase active'
              : 'list-group-item list-group-item-action list-group-item-light p-3 text-uppercase'
          }
          to="/superadmin/packages"
        >
          <i className="fa-solid fa-window-restore me-2" />
          Packages
        </Link>

        {/** Users */}
        <Link
          className={
            splitLocation[2] === 'users'
              ? 'list-group-item list-group-item-action list-group-item-light p-3 text-uppercase active'
              : 'list-group-item list-group-item-action list-group-item-light p-3 text-uppercase'
          }
          to="/superadmin/users"
        >
          <i className="fa-solid fa-user-gear me-2" />
          Users
        </Link>
      </div>
    </React.Fragment>
  );
};

export default AdminSidebar;
