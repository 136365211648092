// jshint ignore:start
import React from 'react';
import { Card } from 'react-bootstrap';
import WebLayout from '../../layouts/WebLayout';

const NodemcuMonitor = () => {
  const url =
    'https://raw.githubusercontent.com/ahmadaait/iot-nodemcu-thingspeak/main/nodemcu_thingspeak.ino';
  fetch(url)
    .then((res) => res.text())
    .then((out) => {
      document.getElementById('show-json-from-git').innerText = out;
    })
    .catch((err) => {
      throw err;
    });

  return (
    <React.Fragment>
      <WebLayout>
        <Card>
          <Card.Body>
            <pre id="show-json-from-git"></pre>
          </Card.Body>
        </Card>
      </WebLayout>
    </React.Fragment>
  );
};

export default NodemcuMonitor;
