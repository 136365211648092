// jshint ignore:start
import React from 'react';
import { Toaster } from 'react-hot-toast';
import PageRoutes from './routes/PageRoutes';

function App() {
  return (
    <React.Fragment>
      <Toaster />
      <PageRoutes />
    </React.Fragment>
  );
}

export default App;
