// jshint ignore:start
import React, { useEffect, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import ClientSidebar from '../components/client/ClientSidebar';
import Api from '../services/Api';
import ApiLogout from '../services/ApiLogout';

const ClientLayout = ({ children }) => {
  const [user, setUser] = useState({});
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const navigate = useNavigate();

  const accessToken = localStorage.getItem('accessToken');

  const fetchData = async () => {
    // fetch on rest api
    await Api.get('/users/profile', {
      headers: {
        // header bearer + token
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      setUser(response.data.data);
    });
  };

  const controlInterval = () => {
    // Get a reference to the last interval + 1
    const interval_id = window.setInterval(function () {},
    Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  };

  useEffect(() => {
    fetchData();
    controlInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function toggle handler
  const sidebarToggleHandler = (e) => {
    e.preventDefault();

    if (!sidebarToggle) {
      document.body.classList.add('sb-sidenav-toggled');
      setSidebarToggle(true);
    } else {
      document.body.classList.remove('sb-sidenav-toggled');
      setSidebarToggle(false);
    }
  };

  const logoutHandler = async (e) => {
    e.preventDefault();

    await ApiLogout.post('/auth/logout', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(() => {
      // remove token
      localStorage.removeItem('accessToken');
      // localStorage.removeItem('role');

      // show toast
      toast.success('Logout Successfully.', {
        duration: 3000,
        position: 'top-right',
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });

      // redirect login page
      navigate('/login');
    });
  };

  return (
    <React.Fragment>
      <div className="d-flex sb-sidenav-toggled" id="wrapper">
        <div className="bg-white" id="sidebar-wrapper">
          <div className="sidebar-heading bg-light text-center">
            <Link to="/" className="text-decoration-none text-white">
              <i className="fa-solid fa-gears me-2"></i>
              <strong>LabM2M</strong>
            </Link>
          </div>
          <ClientSidebar />
        </div>
        <div id="page-content-wrapper">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <button
                className="btn btn-success-dark"
                onClick={sidebarToggleHandler}
              >
                <i className="fa fa-list-ul"></i>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                  <NavDropdown
                    title={user.name}
                    className="fw-bold"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item as={Link} to="/" target="_blank">
                      <i className="fa fa-external-link-alt me-2"></i>
                      Visit Web
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/client/dashboard">
                      <i className="fa fa-folder me-2"></i>
                      Dashboard
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={logoutHandler}>
                      <i className="fa fa-sign-out-alt me-2"></i>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </ul>
              </div>
            </div>
          </nav>
          <div className="container-fluid">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ClientLayout;
