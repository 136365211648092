// jshint ignore:start
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AdminLayout from '../../../layouts/AdminLayout';
import Api from '../../../services/Api';

const EditUser = () => {
  document.title = 'Edit User - LabM2M';

  // get token
  const accessToken = localStorage.getItem('accessToken');

  // state
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [packageCode, setPackageCode] = useState('');
  const [company, setCompany] = useState('');

  const navigate = useNavigate();

  const { _id } = useParams();

  const getUserById = async () => {
    await Api.get(`/users/list/${_id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      setName(response.data.data.name);
      setEmail(response.data.data.email);
      setRole(response.data.data.role);
      setPackageCode(response.data.data.packageCode);
      setCompany(response.data.data.company);
    });
  };

  useEffect(() => {
    getUserById();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUser = async (e) => {
    e.preventDefault();

    await Api.put(
      `/users/list/${_id}`,
      {
        name: name,
        email: email,
        role: role,
        packageCode: packageCode,
        company: company,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(() => {
        toast.success('Data update successfully!', {
          duration: 3000,
          position: 'top-right',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
        navigate('/superadmin/users');
      })
      .then((error) => {
        const status = error.response.status;

        const message =
          status === 400
            ? error.response.data.message[0]
            : error.message.data.message.split(':')[1];

        toast.error(message, {
          duration: 3000,
          position: 'top-right',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
      });
  };

  return (
    <React.Fragment>
      <AdminLayout>
        <div className="row mt-4 mb-5">
          <div className="col-12">
            <div className="card border-0 rounded shadow-sm border-top-success">
              <div className="card-header">
                <span className="font-weight-bold">
                  <Link
                    to={'/superadmin/users'}
                    className="text-decoration-none"
                  >
                    USERS
                  </Link>{' '}
                  / EDIT USER
                </span>
              </div>
              <div className="card-body">
                <form onSubmit={updateUser}>
                  <div className="mb-3">
                    <label className="form-label fw-bold">Name</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">Email</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Email"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">Role</label>
                    <input
                      type="text"
                      className="form-control"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      placeholder="Enter Role"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">Package Code</label>
                    <input
                      type="text"
                      className="form-control"
                      value={packageCode}
                      onChange={(e) => setPackageCode(e.target.value)}
                      placeholder="Enter Package Code"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label fw-bold">Company</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      placeholder="Enter Company"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-md btn-success me-2"
                    >
                      <i className="fa fa-save"></i> SAVE
                    </button>
                    <button type="reset" className="btn btn-md btn-warning">
                      <i className="fa fa-redo"></i> RESET
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </React.Fragment>
  );
};

export default EditUser;
