// jshint ignore:start
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import ClientLayout from '../../../layouts/ClientLayout';
import Api from '../../../services/Api';

const IndexChannel = () => {
  // title
  document.title = 'Channels - LabM2M';

  // access token
  const accessToken = localStorage.getItem('accessToken');

  const [channels, setChannels] = useState([]);

  const navigate = useNavigate();

  // fetch data
  const fetchData = async () => {
    await Api.get(`/channels`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      setChannels(response.data.data);
    });
  };

  // use effect state
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteChannel = (_id) => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'want to delete this data?',
      buttons: [
        {
          label: 'YES',
          onClick: async () => {
            await Api.delete(`/channels/${_id}`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }).then(() => {
              toast.success('Data deleted successfully!', {
                duration: 3000,
                position: 'top-right',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              });

              fetchData();
            });
          },
        },
        {
          label: 'NO',
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <React.Fragment>
      <ClientLayout>
        <div className="container ">
          <div className="row">
            <Card
              className="mx-4 my-4"
              style={{
                width: '18rem',
                minHeight: '18rem',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/client/channels/create')}
            >
              <Link
                to={`/client/channels/create`}
                className="d-flex h-100 w-100 text-decoration-none text-dark align-items-center justify-content-center"
              >
                <Card.Body className="d-flex align-items-center justify-content-center">
                  <Card.Title
                    className="text-center"
                    style={{ fontSize: '2rem', color: '#c5c5c5' }}
                  >
                    <i className="fa-solid fa-plus me-2 mb-2" />
                    <br />
                    Add Channel
                  </Card.Title>
                </Card.Body>
              </Link>
            </Card>
            {channels.map((data, index) => (
              <Card
                className="mx-4 my-4"
                style={{ width: '18rem' }}
                key={index}
              >
                <Card.Body>
                  <Card.Title>{data.name.toUpperCase()}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Status : {data.isPublic ? 'Public' : 'Private'}
                  </Card.Subtitle>
                  <hr />
                  <Card.Text>{data.description}</Card.Text>
                  <Link
                    to={`/client/channels/${data._id}`}
                    className="btn btn-sm btn-success me-2"
                  >
                    <i className="fa fa-tv-alt"></i>
                  </Link>
                  <Link
                    to={`/client/channels/edit/${data._id}`}
                    className="btn btn-sm btn-primary me-2"
                  >
                    <i className="fa fa-pencil-alt"></i>
                  </Link>
                  <Link
                    to={`/client/channels/${data._id}/setting`}
                    className="btn btn-sm btn-primary me-2"
                  >
                    <i className="fa fa-gears"></i>
                  </Link>
                  <button
                    onClick={() => deleteChannel(data._id)}
                    className="btn btn-sm btn-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      </ClientLayout>
    </React.Fragment>
  );
};

export default IndexChannel;
