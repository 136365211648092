// jshint ignore:start
import React from 'react';
import { Link } from 'react-router-dom';
import ChannelsImg from '../../../assets/images/channels.png';
import ChannelsCreateImg from '../../../assets/images/channels_create.png';
import ChannelsEditImg from '../../../assets/images/channels_edit.png';
import ChannelsMonitorImg from '../../../assets/images/channels_monitor.png';
import ChannelsSettingImg from '../../../assets/images/channels_setting.png';
import ClientLayout from '../../../layouts/ClientLayout';

const ClientDashboard = () => {
  // title
  document.title = 'Channels - LabM2M';

  // access token
  // const accessToken = localStorage.getItem('accessToken');

  return (
    <React.Fragment>
      <ClientLayout>
        <div className="row mt-4 mb-5">
          <div className="col-12">
            <div className="card border-0 rounded shadow-sm border-top-success">
              <div className="card-header">
                <span className="font-weight-bold">DASHBOARD</span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h2>Get Started with LabM2M!</h2>
                    <hr />
                  </div>

                  <div className="col-md-6">
                    <h5>1. Channels LabM2M</h5>
                    <p style={{ textAlign: 'justify' }}>
                      Channels adalah tempat pembuatan 1 project. Dalam channel
                      ini, kita dapat mengatur suatu channel sesuai dengan
                      kebutuhan. Sebelum kita membuat channels, akan ditampilkan
                      seperti berikut.
                    </p>
                    <img
                      className="d-flex align-items-center justify-content-center w-100 mt-2 mb-3"
                      src={ChannelsImg}
                      alt="channels"
                    />
                    <hr />
                    <h5>2. Add Channel</h5>
                    <p style={{ textAlign: 'justify' }}>
                      Add Channel digunakan untuk membuat channel baru. Untuk
                      sekarang channel ini maksimal 1 untuk paket free. Namun
                      dapat kita tambahkan lagi sesuai kebutuhan. Didalamnya
                      anda harus mengisikan nama channel, deskripsi channel,
                      field untuk kebutuhan sensor, button value dan teks untuk
                      kebutuhan kontrol aktuator.
                    </p>
                    <img
                      className="d-flex align-items-center justify-content-center w-100 mt-2 mb-3"
                      src={ChannelsCreateImg}
                      alt="channelsCreate"
                    />
                    <hr />
                    <h5>3. Edit Channel</h5>
                    <p style={{ textAlign: 'justify' }}>
                      Bagian ini sama seperti pertama kali kita membuat channel.
                      Namun ditambahkan pilihan untuk mengatur status public
                      atau private channel anda.
                    </p>
                    <img
                      className="d-flex align-items-center justify-content-center w-100 mt-2 mb-3"
                      src={ChannelsEditImg}
                      alt="channelsEdit"
                    />
                    <hr />
                    <h5>4. Setting Channel</h5>
                    <p style={{ textAlign: 'justify' }}>
                      Setting channel digunakan untuk melihat dokumentasi dari
                      channel anda. Didalamnya terdapat Write API Key, Read API
                      Key, dan informasi terkait bagaimana cara menggunakan
                      Write maupun Read API Key.
                    </p>
                    <img
                      className="d-flex align-items-center justify-content-center w-100 mt-2 mb-3"
                      src={ChannelsSettingImg}
                      alt="channelsSetting"
                    />
                    <hr />
                    <h5>5. Monitor Channel</h5>
                    <p style={{ textAlign: 'justify' }}>
                      Pada bagian Monitor Channel akan ditampilkan hasil dari
                      pembacaan sensor tiap data. Tampilan berupa grafik sesuai
                      dengan nilai yang digunakan. <br />
                      <strong>
                        NOTE : Jika anda menambahkan controlling maka akan
                        muncul tombol di bagian kanan atas.
                      </strong>
                    </p>
                    <img
                      className="d-flex align-items-center justify-content-center w-100 mt-2 mb-3"
                      src={ChannelsMonitorImg}
                      alt="channelsMonitor"
                    />
                    <hr />
                  </div>
                  <div className="col-md-6">
                    <h3 className="text-center">EXAMPLE</h3>
                    <hr />
                    <Link
                      to={'/documentation/nodemcu-monitor'}
                      className="text-decoration-none"
                    >
                      <h5>ESP8266 Device Connection For Monitoring</h5>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ClientLayout>
    </React.Fragment>
  );
};

export default ClientDashboard;
