// jshint ignore:start
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Api from '../services/Api';

const Login = () => {
  document.title = 'LabM2M Login';

  // State management
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);

  // Initial navigate
  const navigate = useNavigate();

  // State effect
  useEffect(() => {
    if (localStorage.getItem('accessToken') != null) {
      const accessToken = localStorage.accessToken;
      const decode = jwtDecode(accessToken);
      const decode_role = decode['role'];

      // redirect dashboard page
      if (localStorage.getItem('accessToken') && decode_role === 'superadmin') {
        navigate('/superadmin/dashboard');
      } else if (localStorage.accessToken && decode_role === 'client') {
        navigate('/client/dashboard');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Event login handler
  const loginHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    // const formData = new FormData();

    // formData.append('email', email);
    // formData.append('password', password);

    await Api.post('/auth/login', {
      email: email,
      password: password,
    })
      .then((response) => {
        setLoading(false);

        const accessToken = response.data.accessToken;

        localStorage.setItem('accessToken', accessToken);

        let decoded = jwtDecode(accessToken);
        let decode_role = decoded['role'];

        toast.success('Login Successfully.', {
          duration: 3000,
          position: 'top-right',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });

        navigate(`/${decode_role}/dashboard`);
      })
      .catch((error) => {
        const status = error.statusCode;

        let message = 'Email or password invalid';

        if (status === 401) return message;

        toast.error(message, {
          duration: 3000,
          position: 'top-right',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 mt-100">
            <div className="text-center mb-4">
              <h4>
                <i className="fa-solid fa-gears"></i> Lab Machine to Machine
              </h4>
            </div>
            <div className="card border-0 rounded shadow-sm">
              <div className="card-body">
                <div className="text-center">
                  <h6 className="fw-bold">LOGIN</h6>
                  <hr />
                </div>
                <form onSubmit={loginHandler}>
                  <label className="mb-1">
                    EMAIL ADDRESS <label style={{ color: 'red' }}>*</label>
                  </label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fa fa-envelope"></i>
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Address"
                    />
                  </div>
                  <label className="mb-1">
                    PASSWORD <label style={{ color: 'red' }}>*</label>
                  </label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <i className="fa fa-lock"></i>
                    </span>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                  </div>
                  <button
                    className="btn btn-success shadow-sm rounded-sm px-4 w-100"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? 'LOADING...' : 'LOGIN'}{' '}
                  </button>
                </form>
                <div className="mt-3 ">
                  Don't have an account?
                  <Link to="/register" className="text-decoration-none">
                    {' '}
                    Register Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
