// jshint ignore:start
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChartSensor from '../components/utilities/ChartSensor';
import WebLayout from '../layouts/WebLayout';
import Api from '../services/Api';

const PublicMonitor = () => {
  // title
  document.title = 'Monitor Channel - LabM2M';

  // state
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [field1, setField1] = useState({});
  const [field1Name, setField1Name] = useState('');
  const [field2, setField2] = useState({});
  const [field2Name, setField2Name] = useState('');
  const [field3, setField3] = useState({});
  const [field3Name, setField3Name] = useState('');
  const [field4, setField4] = useState({});
  const [field4Name, setField4Name] = useState('');
  const [field5, setField5] = useState({});
  const [field5Name, setField5Name] = useState('');
  const [field6, setField6] = useState({});
  const [field6Name, setField6Name] = useState('');
  const [field7, setField7] = useState({});
  const [field7Name, setField7Name] = useState('');
  const [field8, setField8] = useState({});
  const [field8Name, setField8Name] = useState('');

  const { _code } = useParams();

  const getDataMonitor = async (code) => {
    await Api.get(`/channels/public/${code}/feeds?limit=20`, {}).then(
      (response) => {
        const channel = response.data.channel;
        const feeds = response.data.feeds;

        setCode(code);
        setName(channel.name);
        setField1(channel.hasOwnProperty('f1') ? feeds : {});
        setField1Name(channel.hasOwnProperty('f1') ? channel.f1 : '');
        setField2(channel.hasOwnProperty('f2') ? feeds : {});
        setField2Name(channel.hasOwnProperty('f2') ? channel.f2 : '');
        setField3(channel.hasOwnProperty('f3') ? feeds : {});
        setField3Name(channel.hasOwnProperty('f3') ? channel.f3 : '');
        setField4(channel.hasOwnProperty('f4') ? feeds : {});
        setField4Name(channel.hasOwnProperty('f4') ? channel.f4 : '');
        setField5(channel.hasOwnProperty('f5') ? feeds : {});
        setField5Name(channel.hasOwnProperty('f5') ? channel.f5 : '');
        setField6(channel.hasOwnProperty('f6') ? feeds : {});
        setField6Name(channel.hasOwnProperty('f6') ? channel.f6 : '');
        setField7(channel.hasOwnProperty('f7') ? feeds : {});
        setField7Name(channel.hasOwnProperty('f7') ? channel.f7 : '');
        setField8(channel.hasOwnProperty('f8') ? feeds : {});
        setField8Name(channel.hasOwnProperty('f8') ? channel.f8 : '');
      }
    );
  };

  useEffect(() => {
    getDataMonitor(_code);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <WebLayout>
        <div className="row mt-4">
          <div className="col-12">
            <div className="card border-0 rounded shadow-sm">
              <div className="card-header">
                <span className="font-weight-bold"></span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h4>{name}</h4>
                    <p>
                      Channel Code : <strong>{code}</strong>
                      <br />
                      Access : <strong>public</strong>
                    </p>
                  </div>

                  <hr />

                  {field1Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field1Name}
                        times={field1.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field1.map((v) => {
                          return v.f1;
                        })}
                        code={code}
                        fieldActive="f1"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field2Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field2Name}
                        times={field2.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field2.map((v) => {
                          return v.f2;
                        })}
                        code={code}
                        fieldActive="f2"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field3Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field3Name}
                        times={field3.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field3.map((v) => {
                          return v.f3;
                        })}
                        code={code}
                        fieldActive="f3"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field4Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field4Name}
                        times={field4.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field4.map((v) => {
                          return v.f4;
                        })}
                        code={code}
                        fieldActive="f4"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field5Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field5Name}
                        times={field3.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field5.map((v) => {
                          return v.f5;
                        })}
                        code={code}
                        fieldActive="f5"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field6Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field6Name}
                        times={field6.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field6.map((v) => {
                          return v.f6;
                        })}
                        code={code}
                        fieldActive="f6"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field7Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field7Name}
                        times={field7.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field7.map((v) => {
                          return v.f7;
                        })}
                        code={code}
                        fieldActive="f7"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {field8Name !== '' ? (
                    <div className="col-12 col-lg-6 mb-4">
                      <ChartSensor
                        name={field8Name}
                        times={field8.map((v) => {
                          return moment(v.created_at).format('ddd hh:mm:ss');
                        })}
                        datalogs={field8.map((v) => {
                          return v.f8;
                        })}
                        code={code}
                        fieldActive="f8"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </WebLayout>
    </React.Fragment>
  );
};

export default PublicMonitor;
