// jshint ignore:start
import axios from 'axios';

const Api = axios.create({
  // set endpoint API
  baseURL: process.env.REACT_APP_BASEURL,

  // set Header
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.statusCode) {
      // remove token
      localStorage.removeItem('accessToken');
      // localStorage.removeItem('role');

      // redirect "/login"
      window.location = '/login';
    } else {
      return Promise.reject(error);
    }
  }
);

export default Api;
