// jshint ignore:start
import { CDBContainer } from "cdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Line } from "react-chartjs-2";
import Api from "../../services/Api";

const ChartSensor = (props) => {
  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  const r = getRandomArbitrary(0, 255);
  const g = getRandomArbitrary(0, 255);
  const b = getRandomArbitrary(0, 255);

  const getDataMonitor = async (code, readKey) => {
    let apiUrl = `channels/public/${code}/feeds?limit=20`;
    if (typeof readKey !== "undefined") {
      apiUrl = `/channels/${code}/feeds?apikey=${readKey}&limit=20`;
    }

    await Api.get(apiUrl, {}).then((response) => {
      const feeds = response.data.feeds;

      if (feeds.length > 0) {
        setData({
          labels: feeds
            .map((v) => {
              return moment(v.created_at).format("ddd hh:mm:ss");
            })
            .reverse(), // per menit
          datasets: [
            {
              label: props.name,
              fill: false,
              lineTension: 0.1,
              backgroundColor: `rgba(${r}, ${g}, ${b}, 0.5)`,
              borderColor: `rgb(${r}, ${g}, ${b})`,
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "#000",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 2,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(71, 225, 167, 0.5)",
              pointHoverBorderColor: "#000",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: feeds
                .map((v, i) => {
                  return feeds[i][props.fieldActive];
                })
                .reverse(),
            },
          ],
        });
      }
    });
  };

  const [data, setData] = useState({
    labels: props.times.reverse(), // per menit
    datasets: [
      {
        label: props.name,
        fill: false,
        lineTension: 0.1,
        backgroundColor: `rgba(${r}, ${g}, ${b}, 0.5)`,
        borderColor: `rgb(${r}, ${g}, ${b})`,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#000",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(71, 225, 167, 0.5)",
        pointHoverBorderColor: "#000",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: props.datalogs.reverse(),
      },
    ],
  });

  useEffect(() => {
    getDataMonitor(props.code, props.readKey);
    setInterval(function () {
      getDataMonitor(props.code, props.readKey);
    }, 15000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CDBContainer>
        <Line data={data} options={{ responsive: true }} />
      </CDBContainer>
    </Card>
  );
};

export default ChartSensor;
